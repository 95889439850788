import React from "react"
import Layout from "@components/layout";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Outline = () => {
  const linkOut1 = 'https://gmo-aozora.com/';

  // Seo情報設定
  const pathName = 'outline';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">Company</h1>
        </div>
        <div>
          <div className="text-center mb-[50px] md:mt-[50px] mt-[60px] "> 
            <h2 className="font-semibold tracking-wide text-xl md:text-[18px]">Outline</h2>

          </div>

          <div className="bus_flex max-w-xl mx-auto  md:max-w-5xl px-6 mb-[60px]">
            <table className="border">
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">商号</td><td className="p-[20px] border-b">株式会社Enzine</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">法人番号</td><td className="p-[20px] border-b">0110-01-148845</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">本店所在地</td><td className="p-[20px] border-b">東京都渋谷区道玄坂一丁目10番8号渋谷道玄坂<br/>東急ビル2F-C</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">設立</td><td className="p-[20px] border-b">令和4年7月20日</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">メールアドレス</td><td className="p-[20px] border-b">contact@enzine-inc.com</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">代表取締役</td><td className="p-[20px] border-b">村上 正祐</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">資本金</td><td className="p-[20px] border-b">100万円</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">取引先銀行</td><td className="p-[20px] border-b"><a href={linkOut1} target="_blank" rel="noopener noreferrer">GMOあおぞらネット銀行</a></td></tr>
            </table>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default Outline


